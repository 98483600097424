.schoolsContainer {
  width: 100%;
  min-height: 50vh;
  border-top: 1rem solid var(--grey200);
  background-color: var(--grey300);
  padding: 1rem 0 0 0;
}

.schools {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(22rem, 1fr));
  grid-gap: 0.5rem;
  padding: 1rem 2rem 2rem 2rem;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
}

.schoolContainer {
  padding: 0.25rem 0;
  cursor: pointer;
}

.schoolTitle {
  display: flex;
  gap: 8px;
}

.schoolCode {
  padding: 0.5rem;
  color: var(--grey600);
  font-weight: 800;
}
