.headerBox {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  height: 6em;
}

.titleBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;
}

.title {
  text-decoration: none;
  color: var(--grey800);
  font-weight: 700;
  font-size: 2em;
  list-style: none;
}

.selectWrapper {
  border-radius: 4px;
  border: 1px solid rgb(158, 158, 158);
  font-size: 1em;
  padding: 8px 8px 8px 12px;
  width: 10em;
  font-weight: bold;
  color: var(--grey800);
  line-height: 1.15em;
  height: 3.2em;
  transition: border-color 300ms ease 0s, box-shadow 300ms ease 0s;
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between; /* align horizontal */
  align-items: center; /* align vertical */
}

.selectBox {
  -webkit-appearance: none;
  background: none;
  border: none;

  width: 100%;
  height: 100%;
  border-raduis: 4px;
}

.selectArrow {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  top: calc(50% - 12px);
  color: rgba(0, 0, 0, 0.54);
  right: 0;
  position: absolute;
  pointer-events: none;
}

.scheduleButton {
  opacity: 0.7;
  transition: 0.15s;
  border: none;
  background: none;
  transition: 0.15s;
  height: 2.5rem;
  width: 2.5rem;

  & :hover {
    opacity: 1;
  }
}

.scheduleIcon {
  color: #212121;
  border-radius: 100%;
}
