.loader.loading {
  opacity: 0.3;
}

.loader {
  opacity: 0;
  position: absolute;
  top: calc(7vmax + 1.6rem);
  left: calc(87vmin - 3.2rem);
  height: 0.8rem;
  z-index: 2;
}

.searchBox {
  font-family: var(--primaryFont);
  position: absolute;
  top: 4vmax;
  left: 10vmin;
  width: 80vmin;
  height: 4rem;
  background-color: white;
  padding: 1.2rem 2rem;
  font-size: 1.3rem;
  cursor: text;
  outline: solid;
  outline-color: var(--grey300);
  border: none;
  transition: outline-color 0.2s;

  &:focus {
    outline-color: var(--purpleMain);
  }

  &::placeholder {
    color: rgb(136, 136, 136);
    font-size: 1.2rem;
  }
}

.searchResults {
  position: absolute;
  z-index: 20;
  top: calc(4vmax + 5rem);
  left: 10vmin;
  width: 80vmin;
}

.course {
  background-color: white;
  padding: 1rem;
  font-size: 1.15rem;
  border: 1px solid var(--grey200);
  color: var(--grey900);
  transition: background-color 0.15s;
  cursor: pointer;
  display: flex;
  box-sizing: border-box;

  &:hover {
    background-color: var(--grey200);
  }
}

.courseSchoolCode {
  font-family: var(--extraCondensedFont);
  font-weight: 500;
  color: var(--grey700);
}

.courseName {
  font-weight: bold;
  margin-left: 1rem;
}
