.heading1 {
  font-size: 2.6rem;
  font-weight: bold;
}

.heading2 {
  font-size: 1.7rem;
  font-weight: bold;
  color: var(--grey700);
}

.body1 {
  font-size: 1.4rem;
  line-height: 1.7rem;
}

.body2 {
  font-size: 1.2rem;
}

.caption {
  font-size: 1rem;
}

.boldInfo {
  font-size: 1.2rem;
  font-weight: bold;
  font-family: var(--condensedFont);
  color: var(--grey600);
}
