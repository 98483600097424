@keyframes verticalFadeIn {
  from {
    opacity: 0;
    padding-top: 2rem;
  }

  to {
    opacity: 1;
    padding-top: 0rem;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation: verticalFadeIn 0.8s ease forwards;
}

.verticalFadeIn {
  animation: verticalFadeIn 0.8s ease forwards;
}
